/* eslint-disable */
export const contacts = [
    {
        id: 'cd5fa417-b667-482d-b208-798d9da3213c',
        avatar: 'images/avatars/male-01.jpg',
        background: 'images/cards/14-640x480.jpg',
        name: 'Dejesus Michael',
        email: 'dejesusmichael@mail.org',
        phoneNumber: '984 531 2468',
        title: 'Track Service Worker',
        address: '279 Independence Avenue, Calvary, Guam, PO4127',
        role: "Admin"
    },
    {
        id: 'beec5287-ed50-4504-858a-5dc3f8ce6935',
        avatar: null,
        background: null,
        name: 'Dena Molina',
        email: 'denamolina@mail.us',
        phoneNumber: '934 537 3180',
        title: 'Weather Analyst',
        address: '856 Woodside Avenue, Alfarata, Iowa, PO4992',
        role: "Admin"
    },
    {
        id: '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf',
        avatar: 'images/avatars/male-02.jpg',
        background: 'images/cards/15-640x480.jpg',
        name: 'Bernard Langley',
        email: 'bernardlangley@mail.com',
        phoneNumber: '893 548 2862',
        title: 'Electromedical Equipment Technician',
        address: '943 Adler Place, Hamilton, South Dakota, PO5592',
        role: "Admin"
    },
    {
        id: '42a5da95-5e6d-42fd-a09d-de755d123a47',
        avatar: 'images/avatars/male-03.jpg',
        background: 'images/cards/16-640x480.jpg',
        name: 'Mclaughlin Steele',
        email: 'mclaughlinsteele@mail.me',
        phoneNumber: '830 484 3813',
        address: '943 Adler Place, Hamilton, South Dakota, PO5592',
        role: "Admin"
    },
    {
        id: 'a7806ced-03f1-4197-8b30-00bdd463366b',
        avatar: 'images/avatars/male-04.jpg',
        background: 'images/cards/17-640x480.jpg',
        name: 'Marsh Cochran',
        email: 'marshcochran@mail.biz',
        phoneNumber: '864 401 3980',
        title: 'Fundraising Director',
        address: '487 Hamilton Walk, Bergoo, American Samoa, PO5616',
        role: "Admin"
    },
    {
        id: 'f4ad15d9-5a24-463a-88ea-6189d6bb3a53',
        avatar: 'images/avatars/male-05.jpg',
        background: 'images/cards/18-640x480.jpg',
        name: 'Parrish Austin',
        email: 'parrishaustin@mail.co.uk',
        phoneNumber: '834 426 3574',
        title: 'Motor Winder',
        address: '610 Harbor Lane, Cascades, Minnesota, PO8639',
        role: "Admin"
    },
    {
        id: '780d0111-5e5c-4694-8d1d-0ea421971fbf',
        avatar: 'images/avatars/female-02.jpg',
        background: 'images/cards/19-640x480.jpg',
        name: 'Laverne Dodson',
        email: 'lavernedodson@mail.ca',
        phoneNumber: '964 417 2318',
        title: 'Television News Producer',
        address: '428 Newport Street, Neahkahnie, Arkansas, PO8324',
        role: "Admin"
    },
    {
        id: 'bf172879-423a-4fd6-8df3-6d1938bbfe1f',
        avatar: 'images/avatars/male-06.jpg',
        background: 'images/cards/20-640x480.jpg',
        name: 'Edwards Mckenzie',
        email: 'edwardsmckenzie@mail.org',
        phoneNumber: '934 519 2903',
        title: 'Legal Assistant',
        address: '384 Polhemus Place, Dalton, Palau, PO6038',
        role: "Admin"
    },
    {
        id: '1eaa3213-ece2-4ba6-8e15-eb36ca388f50',
        avatar: 'images/avatars/female-03.jpg',
        background: 'images/cards/21-640x480.jpg',
        name: 'Trudy Berg',
        email: 'trudyberg@mail.us',
        phoneNumber: '912 539 2770',
        title: 'Meteorologist',
        address: '945 Jerome Avenue, Riceville, North Carolina, PO1625',
        role: "Admin"
    }
];
