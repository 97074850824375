/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id: 'cms',
        title: 'CMS',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/cms'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/users'
    },
    {
        id: 'cms',
        title: 'CMS',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/cms'
    },
    {
        id: 'rbac',
        title: 'RBAC',
        type: 'group',
        icon: 'heroicons_outline:finger-print',
        children: [
            {
                id: 'features',
                title: 'Feature 1',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/'
            },
            {
                id: 'features',
                title: 'Feature 2',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/'
            },
        ]
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
