import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '../permission/permission.service';
import { ModuleName } from '../module.enum';
import { inject } from '@angular/core';
import { catchError, filter, map, of, take } from 'rxjs';

export const roleGuard: CanActivateFn = (route, state) => {
    const authService = inject(PermissionService);
    const router = inject(Router);

    const moduleName = route.data['moduleName'] as ModuleName;
    const actionName = route.data['actionName'] as string;

    return authService.$userRolePermission.pipe(
        filter((permissions) => permissions !== null),
        take(1),
        map((permissions) => {
            const isAllowed = authService.IsActionAllowed(moduleName, actionName);
            if (!isAllowed) {
                router.navigate(['/404']).then((r) => r);
            }
            return isAllowed;
        }),
        catchError((error) => {
            console.error('Error  permissions:', error);
            router.navigate(['/404']).then((r) => r);
            return of(false);
        })
    );
};


