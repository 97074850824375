import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiEndpointsService } from 'app/core/service/api-endpoints.service';
import { ApiHttpService } from 'app/core/service/api-http-service';
import {
    BehaviorSubject,
    map,
    Observable,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TasksService {

    private apiHttpService = inject(ApiHttpService);
    private apiEndpointsService = inject(ApiEndpointsService)

    // Private
    private _screens: BehaviorSubject<string[]> = new BehaviorSubject(['home', 'gallery']);
    public _screensWithSections: BehaviorSubject<any> = new BehaviorSubject(null);
    public _activeScreen: BehaviorSubject<string> = new BehaviorSubject('home');
    public _cmsData: BehaviorSubject<any> = new BehaviorSubject(null);
    public _cmsItem: BehaviorSubject<any> = new BehaviorSubject(null);
    public _cmsActiveItem: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for screens
     */
    get screens$(): Observable<string[]> {
        return this._screens.asObservable();
    }

    /**
     * Getter for active screen
     */
    get activeScreen$(): Observable<string> {
        return this._activeScreen.asObservable();
    }

    /**
     * Getter for cms data
     */
    get cmsData$(): Observable<string> {
        return this._cmsData.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setActiveScreen(screen: string): void {
        this._activeScreen.next(screen);
    }


    getScreen(): Observable<string[]> {
        const url = this.apiEndpointsService.createUrl('cms/screens-list');
        return this.apiHttpService.get(url).pipe(
            map((response: any) => {
                // Extract the array of strings from the response
                const screens = response.data?.map((item) => item.screen);
                this._screens.next(screens);
                this._screensWithSections.next(response.data);
                return this._screens.value;
            })
        );
    }


    getScreenItems(): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithQueryParameters('cms', (queryStringParameters) => {
            queryStringParameters.push('screen', this._activeScreen.value);
            queryStringParameters.push('perpage', 100);
        });
        return this.apiHttpService.get(url).pipe(
            map((response: any) => {
                this._cmsData.next({ data: response?.data?.data, meta: response?.data?.meta });
                return { data: response?.data?.data, meta: response?.data?.meta };
            })
        )
    }

    getDataById(id: string, type: string): Observable<any> {
        let path = "cms-item";
        if (type === 'section') {
            path = 'cms';
        }
        const url = this.apiEndpointsService.createUrlWithPathVariables(path, [id]);

        return this.apiHttpService.get(url).pipe(
            map((response: any) => {
                this._cmsActiveItem.next(response?.data);
                return response?.data;
            })
        )
    }

    getCmsItemById(id: string): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithPathVariables('cms-item', [id]);

        return this.apiHttpService.get(url).pipe(
            map((response: any) => {
                this._cmsItem.next(response?.data);
                return response?.data;
            })
        )

    }

    updateCmsSection(id: string, data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithPathVariables('cms', [id]);
        return this.apiHttpService.put(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    createCmsSection(data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrl('cms');
        return this.apiHttpService.post(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    createCmsItem(data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrl('cms-item');
        return this.apiHttpService.post(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }


    updateCmsItem(id: string, data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithPathVariables('cms-item', [id]);
        return this.apiHttpService.put(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    deleteCmsItem(id: string): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithPathVariables('cms-item', [id]);
        return this.apiHttpService.delete(url).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    deleteCmsSection(id: string): Observable<any> {
        const url = this.apiEndpointsService.createUrlWithPathVariables('cms', [id]);
        return this.apiHttpService.delete(url).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    updateCmsSectionOrder(data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrl('cms/update-priorities');
        return this.apiHttpService.put(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }

    updateCmsItemOrder(data: any): Observable<any> {
        const url = this.apiEndpointsService.createUrl('cms-item/update-priorities');
        return this.apiHttpService.put(url, data).pipe(
            map((response: any) => {
                this.getScreenItems().subscribe();
                return response;
            })
        )
    }
}
