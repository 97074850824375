import {
    AfterViewInit,
    Component,
    inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { UserComponent } from 'app/layout/common/user/user.component';
import { TasksService } from 'app/modules/admin/tasks/tasks.service';
import { Subject, takeUntil } from 'rxjs';
import { PermissionService } from '../../../../shared/services/permission/permission.service';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        UserComponent,
        MatIconModule,
        MatButtonModule,
        FuseFullscreenComponent,
        RouterOutlet,
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private taskService = inject(TasksService);
    public navList = [];
    customeNav: FuseNavigationItem[] = [
        {
            id: 'customer-dashboard',
            title: 'Customer Dashboard',
            type: 'basic',
            icon: 'dashboard',
            link: '/customer-dashboard',
        },
        {
            icon: 'chair',
            id: 'seat-layout',
            link: '/seat-layout',
            title: 'Seat Layout',
            type: 'basic',
        },
        {
            icon: 'local_cafe',
            id: 'foodBooking',
            link: '/food-booking',
            title: 'Food Purchase',
            type: 'basic',
        },
        {
            icon: 'book_online',
            id: 'ticketBooking',
            link: '/ticket-booking',
            title: 'Ticket Booking',
            type: 'basic',
        },
        {
            icon: 'group',
            id: 'customers',
            link: '/customers',
            title: 'Customers',
            type: 'basic',
        },
        {
            icon: 'manage_accounts',
            id: 'users',
            link: '/users',
            title: 'User Management',
            type: 'collapsable',
            children: [
                {
                    id: 'roles',
                    link: '/roles',
                    title: 'Roles',
                    type: 'basic',
                },
                {
                    id: 'users',
                    link: '/users',
                    title: 'Users',
                    type: 'basic',
                }
            ]
        },
        {
            id: 'cms',
            title: 'Website',
            type: 'collapsable',
            icon: 'web',
            link: '/cms',
            children: [
                {
                    id: 'packages',
                    title: 'Packages',
                    type: 'basic',
                    // icon: 'heroicons_outline:cube',
                    link: '/packages',
                },
            ],
        },
        {
            id: 'products',
            title: 'Foods',
            type: 'collapsable',
            icon: 'food_bank',
            children: [
                {
                    id: 'categories',
                    title: 'Categories',
                    type: 'basic',
                    link: '/inventory/categories',
                },
                {
                    id: 'ingredients',
                    title: 'Ingredients',
                    type: 'basic',
                    link: '/inventory/ingredients',
                },
                {
                    id: 'products',
                    title: 'Products',
                    type: 'basic',
                    link: '/inventory/products',
                },
            ]
        },
        {
            id: 'inventory',
            title: 'Ledger',
            type: 'collapsable',
            icon: 'inventory',
            children: [
                {
                    id: 'categories',
                    title: 'Item Categories',
                    type: 'basic',
                    link: '/inventory/inventory-categories',
                },
                {
                    id: 'items',
                    title: 'Inventory Items',
                    type: 'basic',
                    link: '/inventory/inventory-items',
                },
                {
                    id: 'inventory',
                    title: 'Inventory',
                    type: 'basic',
                    link: '/inventory/list',
                },
                {
                    id: 'inventory',
                    title: 'Inventory Logs',
                    type: 'basic',
                    link: '/inventory/logs',
                },
                {
                    id: 'expense-category',
                    title: 'Expense Category',
                    type: 'basic',
                    link: '/inventory/expenses-category',
                },
                {
                    id: 'expense',
                    title: 'Expense',
                    type: 'basic',
                    link: '/inventory/inventory-expenses',
                },
                {
                    id: 'funds',
                    title: 'Fund',
                    type: 'basic',
                    link: '/inventory/funds',
                },
            ]
        },
        {
            id: 'ticket-management',
            title: 'Ticket Management',
            type: 'collapsable',
            icon: 'dashboard_customize',
            children: [
                {
                    id: 'ticket-schedule',
                    title: 'Ticket Schedule',
                    type: 'basic',
                    link: '/ticket/schedule',
                },
                {
                    id: 'shifts',
                    title: 'Shifts',
                    type: 'basic',
                    link: '/shifts',
                },
                {
                    id: 'floor',
                    title: 'Floor',
                    type: 'basic',
                    link: '/floor',
                },
            ],
        },
        {
            id: 'food_order',
            title: 'Food Orders',
            type: 'basic',
            icon: 'receipt',
            link: '/food-orders',
        },
        {
            id: 'ticket_order',
            title: 'Ticket Order',
            type: 'collapsable',
            icon: 'confirmation_number',
            children: [
                {
                    id: 'order',
                    title: 'Get Pass',
                    type: 'basic',
                    link: '/order',
                },
                {
                    id: 'order-list',
                    title: 'Order List',
                    type: 'basic',
                    link: '/order-list',
                },
                {
                    id: 'transactions',
                    title: 'Transactions',
                    type: 'basic',
                    link: '/transactions',
                },
            ],
        },
        {
            id: 'transport_management',
            title: 'Transport',
            type: 'collapsable',
            icon: 'heroicons_outline:truck',
            children: [
                {
                    id: 'area_pricing',
                    title: 'Area & Pricing',
                    type: 'basic',
                    link: '/area-pricing',
                },
                {
                    id: 'vehicles',
                    title: 'Vehicles',
                    type: 'basic',
                    link: '/vehicles',
                },
            ],
        },
        // {
        //     id: 'rbac',
        //     title: 'RBAC',
        //     type: 'collapsable',
        //     icon: 'heroicons_outline:finger-print',
        //     children: [
        //         {
        //             id: 'features',
        //             title: 'Feature 1',
        //             type: 'basic',
        //             icon: 'heroicons_outline:cog',
        //             link: '/f1',
        //         },
        //         {
        //             id: 'features',
        //             title: 'Feature 2',
        //             type: 'basic',
        //             icon: 'heroicons_outline:cog',
        //             link: '/f2',
        //         },
        //     ],
        // },
    ];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _permissionService: PermissionService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.taskService.getScreen().subscribe((screen: any) => {
            let cmsNav = this.customeNav.find((nav) => nav.id === 'cms');
            cmsNav.children = [
                ...cmsNav.children,
                ...screen?.map((s) => {
                    return {
                        id: s,
                        title: this.makeFirstLetterUppercase(s),
                        type: 'basic',
                        link: `/cms/${s}`,
                    };
                })
            ]
        });
    }

    makeFirstLetterUppercase(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.navList = [];

        this._permissionService.$userRolePermission.subscribe((response) => {
            if (response) {
                this.navList = [];
                if (this._permissionService.getModules().length > 0) {
                    this.customeNav.forEach((nav: any, index) => {
                        if (this._permissionService.IsActionAllowed(nav.title, 'view')) {
                            this.navList.push(nav)
                        }
                        // if (nav.title === 'Ledger' || nav.id === 'food_order' || nav.id === 'foodBooking') {
                        //     this.navList.push(nav)
                        // }
                    })
                }
            }
        })
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngAfterViewInit() {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
