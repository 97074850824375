import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { io, Socket } from 'socket.io-client';
import { environment } from '../environments/environment';
import { HelperServiceService } from './shared/services/helper/helper-service.service';
import { AuthService } from './core/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, FullCalendarModule],
})
export class AppComponent implements OnInit , OnDestroy{
    private socket!: Socket;

    /**
     * Constructor
     */
    constructor( private helperService: HelperServiceService,
                 private authService: AuthService,
    ) {}

    ngOnInit(): void {
        // Initialize the WebSocket connection
        this.socket = io(environment.socketUrl); // Replace with your server URL

        // Listen for events from the server
        this.socket.on('custom-event', (data: any) => {
            console.log('Received:', data);
            this.helperService.$updateSeatLayoutSubject.next(data);``
        });

        // Optionally log connection status
        this.socket.on('connect', () => {
            console.log('Connected to WebSocket server');
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket server');
        });

        // Handle browser/tab close
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        const userData: any = JSON.parse(localStorage.getItem('userInfo'));
        if (userData) {
            this.authService.getPermissionsForUser(userData?.roles[0]?.id)
        }

    }
    ngOnDestroy(): void {
        // Cleanup: Disconnect the socket and remove event listener
        if (this.socket) {
            this.socket.disconnect();
        }
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    private handleBeforeUnload = (): void => {
        if (this.socket) {
            this.socket.disconnect(); // Ensure the socket disconnects on browser/tab close
        }
    };
}
